import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { MenuPopover, IconButtonAnimate, Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import { EApiStatus } from '@type/common';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from 'services/AuthService';
import { usePointsService } from 'services/PointsService';
import { useSnackbar } from 'notistack';
import path from 'routes/path';
import cookies, { KeyCookie } from 'plugins/cookie/cookie';
import axios from 'plugins/api/axios';
import { IStaff } from '@type/staff.type';
import EditNicknameModal from './EditNicknameModal';

const { backstage } = axios;
const { Box, Divider, Typography, MenuItem, Avatar, useTheme } = Mui;

// ----------------------------------------------------------------------

type TProps = {
  loginUser: any;
  setLoginUser: (arg: any) => void;
};

const AccountPopover = ({ loginUser, setLoginUser }: TProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [, { emptyAuthData }] = useAuthService();
  const [, { emptyPointsData }] = usePointsService();
  const navigate = useNavigate();
  const { name, staffNumber, nickname, id } = loginUser;
  const [open, setOpen] = useState<HTMLElement | null>(null);
  // 編輯個人暱稱
  const [openEditNicknameModal, setOpenEditNicknameModal] = useState(false);
  const [editNickname, setEditNickname] = useState(nickname ?? '');
  const [loading, setLoading] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    const oneClassMMSToken = cookies.get(
      KeyCookie.oneClassMMSClient,
    )?.accessToken;
    const oneClassToken = cookies.get(KeyCookie.oneClassClient);
    if (oneClassToken?.jwt?.includes(oneClassMMSToken)) {
      cookies.remove(KeyCookie.oneClassClient);
    }
    cookies.remove(KeyCookie.oneClassMMSClient);
    cookies.remove(KeyCookie.examRole);
    navigate(path.LOGIN);
    emptyAuthData();
    emptyPointsData();
    setLoginUser(undefined);
  };

  // 開啟編輯暱稱彈窗
  const handleOpenEditNicknameModal = () => {
    setOpenEditNicknameModal(true);
  };

  // 編輯暱稱確認
  const handleEditNickname = async () => {
    const payload = {
      nickname: editNickname,
    };

    setLoading(true);
    const { status, data } = await backstage.updateStaff(payload, id);

    if (status === EApiStatus.SUCCESS) {
      enqueueSnackbar('編輯暱稱成功', { variant: 'success' });
      setLoginUser((prev: IStaff) => ({
        ...prev,
        nickname: data.nickname,
      }));
    } else {
      enqueueSnackbar('編輯暱稱失敗', { variant: 'error' });
    }

    setOpenEditNicknameModal(false);
    setLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          position: 'relative',
        }}
      >
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
          css={{}}
        >
          <Avatar src="" alt="oneClub" />
        </IconButtonAnimate>
      </Box>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {staffNumber}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: '2px' }}
              noWrap
            >
              {nickname}
            </Typography>
            <Box onClick={handleOpenEditNicknameModal}>
              <Icon
                name={SvgName.Edit}
                sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </MenuPopover>
      {openEditNicknameModal && (
        <EditNicknameModal
          open={openEditNicknameModal}
          onClose={() => setOpenEditNicknameModal(false)}
          onDisConfirm={() => setOpenEditNicknameModal(false)}
          handleEditNickname={handleEditNickname}
          editNickname={editNickname}
          setEditNickname={setEditNickname}
          loading={loading}
        />
      )}
    </>
  );
};

export default AccountPopover;
