import type { GroupChannel } from '@sendbird/chat/groupChannel';
import ChannelPreview from '@sendbird/uikit-react/ChannelList/components/ChannelPreview';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';
import { EChannelTargetType } from 'components/sendbird/sendbirdKit.constant';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { useSendbirdStateContext } from '@sendbird/uikit-react/useSendbirdStateContext';
import { useSendbirdService } from 'services/SendbirdService';
import { useLoginService } from 'services/LoginService';
import queryString from 'query-string';
import path, { Auth } from 'routes/path';
import { alpha, styled } from '@mui/material/styles';
import { Mui } from '@onedesign/ui';

const { Box, Divider, useTheme, CircularProgress, Typography } = Mui;

const ContainerStyles = styled(Box)(({ theme }) => ({
  boxShadow: `-20px 20px 40px -4px  ${alpha(theme.palette.grey[500], 0.24)}`,
  height: '550px',
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -20,
    top: 13,
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

const ActiveDivider = styled(Divider)({
  position: 'absolute',
  width: '115%',
  height: '2px',
  top: 38,
});

const TabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '13px 20px',
  gap: '28px',
  color: theme.palette.grey[800],
  cursor: 'pointer',
}));

const TabItem = styled(Box)({
  width: '56px',
  position: 'relative',
});

interface RenderChannelPreviewProps {
  channel: GroupChannel;
}

export interface ChannelListUIProps {
  renderChannelPreview?: (
    props: RenderChannelPreviewProps,
  ) => React.ReactElement;
  setCurrentChannelSelected?: (channel: GroupChannel) => void;
  currentChannelSelected?: GroupChannel;
  value: string;
  setValue: (value: EChannelTargetType) => void;
}

const ChannelListUI: React.FC<ChannelListUIProps> = ({
  setCurrentChannelSelected,
  currentChannelSelected,
  value,
  setValue,
}: ChannelListUIProps) => {
  const { allChannels, loading, initialized } = useChannelListContext();
  const state = useSendbirdStateContext();
  const config = state?.config;
  const { isOnline = false } = config;
  const { loginUser } = useLoginService();
  const {
    sendbirdAccessToken,
    customerChannelUnreadCount,
    teacherChannelUnreadCount,
  } = useSendbirdService();
  const limitedChannels = allChannels?.slice(0, 5) || [];
  const theme = useTheme();
  const permissions = loginUser?.permissions || [];
  const includeAuth = (auth: string[]) =>
    auth.some((item: any) => permissions.includes(item));

  // 點查看全部
  const handleOpenSendbird = () => {
    value === EChannelTargetType.TEACHER
      ? window.open(`${path.SENDBIRD_TEACHER}`, '_blank')?.focus()
      : window.open(`${path.SENDBIRD_CUSTOMER}`, '_blank')?.focus();
  };

  // 點特定對話匡=>channel資料塞到網址
  const handleOpenSelectedChannel = (channel: GroupChannel) => {
    const channelUrl = channel?.url;
    const channelCustomType = channel?.customType;

    if (value === EChannelTargetType.TEACHER) {
      const queryPath = queryString.stringifyUrl({
        url: path.SENDBIRD_TEACHER,
        query: {
          channelUrl,
          channelCustomType,
        },
      });
      window.open(queryPath, '_blank');
    } else {
      const queryPath = queryString.stringifyUrl({
        url: path.SENDBIRD_CUSTOMER,
        query: {
          channelUrl,
        },
      });
      window.open(queryPath, '_blank');
    }
  };

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <ContainerStyles>
      {initialized && !loading && (
        <>
          <Box
            sx={{
              px: '20px',
              py: '10px',
              color: theme.palette.grey[800],
              fontWeight: 500,
            }}
          >
            聊天室
          </Box>
          <TabContainer>
            {includeAuth([Auth.TEACHER_TALK]) && (
              <TabItem onClick={() => setValue(EChannelTargetType.TEACHER)}>
                <StyledBadge
                  badgeContent={teacherChannelUnreadCount}
                  color="error"
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    教師
                  </Typography>
                </StyledBadge>
                <ActiveDivider
                  sx={{
                    backgroundColor:
                      value === EChannelTargetType.TEACHER
                        ? theme.palette.primary.main
                        : '',
                  }}
                />
              </TabItem>
            )}
            {includeAuth([Auth.PARENT_MESSAGE_EDIT]) && (
              <TabItem onClick={() => setValue(EChannelTargetType.CUSTOMER)}>
                <StyledBadge
                  badgeContent={customerChannelUnreadCount}
                  color="error"
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    家長
                  </Typography>
                </StyledBadge>
                <ActiveDivider
                  sx={{
                    backgroundColor:
                      value === EChannelTargetType.CUSTOMER
                        ? theme.palette.primary.main
                        : '',
                  }}
                />
              </TabItem>
            )}
          </TabContainer>
          <Divider />
          {limitedChannels.length > 0 && (
            <>
              <div className="sendbird-channel-list__body">
                <div>
                  {limitedChannels &&
                    limitedChannels.map((channel, idx) => {
                      const onClick = () => {
                        if (!isOnline) {
                          return;
                        }
                        setCurrentChannelSelected?.(channel);
                        handleOpenSelectedChannel(channel);
                      };

                      return (
                        <ChannelPreview
                          key={channel?.url}
                          tabIndex={idx}
                          onClick={onClick}
                          channel={channel}
                          isSelected={
                            channel?.url === currentChannelSelected?.url
                          }
                          renderChannelAction={() => <></>}
                        />
                      );
                    })}
                </div>
              </div>
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  ml: '124px',
                  mr: '124px',
                  mt: '14px',
                  mb: '14px',
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
                onClick={handleOpenSendbird}
              >
                查看全部
              </Box>
            </>
          )}
        </>
      )}
      {loading && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </ContainerStyles>
  );
};

export default ChannelListUI;
