import { lazy, useMemo } from 'react';
import { Navigate, RouteObject, useLocation } from 'react-router-dom';
import { useLoginService } from 'services/LoginService';
import { LogoOnlyLayout, Image } from '@onedesign/ui';
import { resolveAuthorizedPath } from 'utils/helpr/permission';
import { isNotNull } from 'utils/typeGuard';
import { useRemoteConfigService } from 'services/RemoteConfigService';
import Login from 'pages/Login';
import { DashboardLayout } from 'layouts/dashboard';
import { SendbirdChatLayout } from 'layouts/sendbird-chat';
import { DisplayLayout } from 'layouts/display-layout';
import path, { Auth, Route } from '../path';
import { Loadable } from '../utils';
import { generateDataCentralRoutes } from './data-central';
import { generateCustomerRoutes } from './customer';
import { generateAuditionRoutes } from './audition';
import { generateTutorialCenterRoutes } from './tutorial-center';
import { generateElectronicCommerceRoutes } from './electronic-commerce';
import { generateTeachersManagementRoutes } from './teachers-management';
import { generateReportRoutes } from './report';
import { generateFinancialRoutes } from './financial';
import { generateAuthorityRoutes } from './authority';
import { generateShipManagementRoutes } from './ship-management';
import { generateOneAnswerRoutes } from './one-answer';
import { generateAdvertisementRoutes } from './advertisement';
import { generateCustomerTicketRoutes } from './customer-ticket';
import { generateNotificationRoutes } from './notification';
import { generateSendbirdTeacherRoutes } from './sendbird-teacher';
import { generateSendbirdCustomerRoutes } from './sendbird-customer';
import { generateLineChatManagementRoutes } from './line-chat-management';
import { generateDisplayRoutes } from './display';
import logoIcon from '@/favicon.svg';

const Register = Loadable(lazy(() => import('pages/Register')));
const PermissionAbnormal = Loadable(
  lazy(() => import('pages/PermissionAbnormal')),
);
const NotFound = Loadable(lazy(() => import('pages/Page404')));
const ServerError = Loadable(lazy(() => import('pages/Server500')));

const RouteConfig = () => {
  const { loginUser } = useLoginService();
  const permissions = (loginUser?.permissions || []) as Auth[];
  const { pathname } = useLocation();
  const { parameter } = useRemoteConfigService();

  const routes = [];

  routes.push({
    path: Route.root,
    element: <DashboardLayout />,
    children: useMemo(() => {
      const rootFolder: (RouteObject | null)[] = [];

      rootFolder.push({
        element: (
          <Navigate
            to={(() => {
              if (!loginUser) {
                return path.LOGIN;
              }
              return resolveAuthorizedPath(permissions);
            })()}
          />
        ),
        index: true,
      });

      // 數據中心
      rootFolder.push(generateDataCentralRoutes(permissions));

      // 會員管理
      rootFolder.push(generateCustomerRoutes(permissions));

      // 課務管理
      rootFolder.push(generateAuditionRoutes(permissions));

      // 補教課程管理
      rootFolder.push(generateTutorialCenterRoutes(permissions));

      // 電子商務
      rootFolder.push(generateElectronicCommerceRoutes(permissions));

      // 教務管理
      rootFolder.push(generateTeachersManagementRoutes(permissions));

      // 統計報表
      rootFolder.push(generateReportRoutes(permissions));

      // 財務管理
      rootFolder.push(generateFinancialRoutes(permissions));

      // 權限管理
      rootFolder.push(generateAuthorityRoutes(permissions));

      // 出貨管理
      rootFolder.push(generateShipManagementRoutes(permissions));

      // OneAnswer 管理
      rootFolder.push(generateOneAnswerRoutes(loginUser));

      // 廣告管理
      rootFolder.push(generateAdvertisementRoutes(loginUser));

      // 會員案件管理
      parameter.ONECLUB_6599 &&
        rootFolder.push(generateCustomerTicketRoutes(loginUser, parameter));

      // 通知中心
      rootFolder.push(generateNotificationRoutes(loginUser));

      return rootFolder.filter(isNotNull);
    }, [loginUser, parameter]),
  });

  routes.push({
    path: Route.root,
    element: <SendbirdChatLayout />,
    children: useMemo(() => {
      const rootFolder: (RouteObject | null)[] = [];

      // Sendbird Teacher
      rootFolder.push(generateSendbirdTeacherRoutes(permissions));

      // Sendbird Customer
      rootFolder.push(generateSendbirdCustomerRoutes(permissions));

      rootFolder.push(generateLineChatManagementRoutes());

      return rootFolder.filter(isNotNull);
    }, [parameter]),
  });

  routes.push({
    path: Route.root,
    element: <DisplayLayout />,
    children: useMemo(() => {
      const rootFolder: (RouteObject | null)[] = [];

      rootFolder.push(generateDisplayRoutes());

      return rootFolder.filter(isNotNull);
    }, [parameter]),
  });

  routes.push(
    {
      path: Route.login,
      element: <Login />,
    },
    {
      path: Route.register,
      element: <Register />,
    },
    {
      path: Route.all,
      element: (
        <LogoOnlyLayout
          logo={<Image src={logoIcon} sx={{ width: '50px' }} />}
        />
      ),
      children: [
        {
          path: Route.all,
          element: (
            <Navigate
              to={
                Object.values(path).includes(pathname)
                  ? path.LOGIN
                  : path.NOT_FOUND
              }
              replace
            />
          ),
        },
        {
          path: Route.permissionAbnormal,
          element: <PermissionAbnormal />,
        },
        { path: Route.serverError, element: <ServerError /> },
        { path: Route.notFound, element: <NotFound /> },
      ],
    },
  );

  return routes.filter(isNotNull);
};

export default RouteConfig;
