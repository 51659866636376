import path, {
  Auth,
  FirstRouteName,
  RouteName,
  historyTicketsExport,
  historyTicketsRead,
  parentMessageRead,
  ticketsEdit,
  ticketsRead,
  parentTicketsNew,
  parentTicketsHistoryRead,
} from 'routes/path';
import { TParameter } from 'services/RemoteConfigService/remoteConfigService.type';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateCustomerTicketNav = (
  permissions: Auth[],
  parameter: TParameter,
) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  return {
    title: RouteName.customerticket,
    path: path.CUSTOMER_TICKET,
    icon: ICONS.customerTicket,
    children: (() => {
      const newNav = [];

      (includeAuth(ticketsRead) || includeAuth(ticketsEdit)) &&
        newNav.push({
          title: FirstRouteName.instantcase,
          path: path.CUSTOMER_TICKET_INSTANT_CASE,
        });

      newNav.push({
        title: FirstRouteName.mycase,
        path: path.CUSTOMER_TICKET_MY_CASE,
      });

      (includeAuth(historyTicketsRead) || includeAuth(historyTicketsExport)) &&
        newNav.push({
          title: FirstRouteName.historycase,
          path: path.CUSTOMER_TICKET_HISTORY_CASE,
        });

      parameter.ONECLUB_7111 &&
        (includeAuth(parentMessageRead) ||
          includeAuth(parentTicketsNew) ||
          includeAuth(parentTicketsHistoryRead)) &&
        newNav.push({
          title: FirstRouteName.memberchannelmanagement,
          path: path.CUSTOMER_TICKET_MEMBER_CHANNEL_MANAGEMENT,
        });

      return newNav;
    })(),
  };
};

export { generateCustomerTicketNav };
