import type { GroupChannel } from '@sendbird/chat/groupChannel';
import ChannelPreview from '@sendbird/uikit-react/ChannelList/components/ChannelPreview';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';
import { useSendbirdStateContext } from '@sendbird/uikit-react/useSendbirdStateContext';
import { useSendbirdService } from 'services/SendbirdService';
import { useLoginService } from 'services/LoginService';
import path from 'routes/path';
import { alpha, styled } from '@mui/material/styles';
import { Mui } from '@onedesign/ui';

const { Box, Divider, useTheme, CircularProgress } = Mui;

const ContainerStyles = styled(Box)(({ theme }) => ({
  boxShadow: `-20px 20px 40px -4px  ${alpha(theme.palette.grey[500], 0.24)}`,
}));

interface RenderChannelPreviewProps {
  channel: GroupChannel;
}

export interface ChannelListUIProps {
  renderChannelPreview?: (
    props: RenderChannelPreviewProps,
  ) => React.ReactElement;
  setCurrentChannelSelected?: (channel: GroupChannel) => void;
  currentChannelSelected?: GroupChannel;
}

const ChannelListUI: React.FC<ChannelListUIProps> = ({
  setCurrentChannelSelected,
  currentChannelSelected,
}) => {
  const { allChannels, loading, initialized } = useChannelListContext();
  const state = useSendbirdStateContext();
  const config = state?.config;
  const { isOnline = false } = config;
  const { loginUser } = useLoginService();
  const { sendbirdAccessToken } = useSendbirdService();
  const limitedChannels = allChannels?.slice(0, 5) || [];
  const theme = useTheme();

  // 點查看全部
  const handleOpenSendbird = () => {
    window.open(`${path.CUSTOMER_TICKET_MY_CASE}`, '_blank')?.focus();
  };

  // 點特定對話匡=>channel資料塞到網址
  const handleOpenSelectedChannel = (channel: GroupChannel) => {
    const channelUrl = channel?.url;
    const queryString = `?channelUrl=${channelUrl}`;
    window.open(path.CUSTOMER_TICKET_MY_CASE + queryString, '_blank');
  };

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <ContainerStyles>
      {initialized && !loading && (
        <>
          <Box
            sx={{
              pl: '20px',
              pt: '10px',
              color: theme.palette.grey[800],
              fontWeight: 500,
              height: '40px',
            }}
          >
            案件通知
          </Box>
          <Divider />
          {limitedChannels.length > 0 && (
            <>
              <div className="sendbird-channel-list__body">
                <div>
                  {limitedChannels &&
                    limitedChannels.map((channel, idx) => {
                      const onClick = () => {
                        if (!isOnline) {
                          return;
                        }
                        setCurrentChannelSelected?.(channel);
                        handleOpenSelectedChannel(channel);
                      };

                      return (
                        <ChannelPreview
                          key={channel?.url}
                          tabIndex={idx}
                          onClick={onClick}
                          channel={channel}
                          isSelected={
                            channel?.url === currentChannelSelected?.url
                          }
                          renderChannelAction={() => <></>}
                        />
                      );
                    })}
                </div>
              </div>
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  ml: '124px',
                  mr: '124px',
                  mt: '14px',
                  mb: '14px',
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
                onClick={handleOpenSendbird}
              >
                查看全部
              </Box>
            </>
          )}
        </>
      )}
      {loading && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </ContainerStyles>
  );
};

export default ChannelListUI;
