import {
  afterClassStudyRead,
  auditionCourseRead,
  auditionLearningCoach,
  auditionRead,
  auditionVipPass,
  authorityDepartment,
  authorityEmployee,
  authorityRole,
  badgeRead,
  batchScheduledCoursesRead,
  courseFormalAuditionRead,
  courseReviewRead,
  courseTagRead,
  customerDataRead,
  dataCentralFinanceRead,
  dataCentralSalesRead,
  electroniccommerceCommodity,
  electroniccommerceOrder,
  electroniccommerceSetting,
  laborCostRead,
  performanceExpenseRead,
  realtimeCourseRead,
  reportDaily,
  reportPerformanceRead,
  reportRealtime,
  revenueRead,
  salaryOverallRead,
  teacherApplicationRead,
  teacherApplicationReadUnverified,
  teacherContractRead,
  teacherListRead,
  teacherMaterialRead,
  teacherSalaryRead,
  textbookRead,
  tutorialCenterCourseRead,
  visiterDataRead,
} from 'routes/path';

export const dataCentralAuth = [
  ...dataCentralSalesRead,
  ...dataCentralFinanceRead,
];

export const customerVisitorAuth = [...customerDataRead, ...visiterDataRead];

export const auditionAuth = [
  ...auditionLearningCoach,
  ...auditionVipPass,
  ...auditionRead,
  ...auditionCourseRead,
  ...courseFormalAuditionRead,
  ...afterClassStudyRead,
  ...batchScheduledCoursesRead,
  ...realtimeCourseRead,
  ...courseTagRead,
  ...courseReviewRead,
];

export const tutorialCenterAuth = [...tutorialCenterCourseRead];

export const electroniccommerceAuth = [
  ...electroniccommerceOrder,
  ...electroniccommerceCommodity,
  ...electroniccommerceSetting,
];

export const teachersManagementAuth = [
  ...teacherApplicationRead,
  ...teacherApplicationReadUnverified,
  ...teacherContractRead,
  ...teacherListRead,
  ...teacherMaterialRead,
];

export const reportAuth = [
  ...reportDaily,
  ...reportPerformanceRead,
  ...reportRealtime,
];

export const financialAuth = [
  ...laborCostRead,
  ...performanceExpenseRead,
  ...revenueRead,
  ...salaryOverallRead,
  ...teacherSalaryRead,
];

export const authorityAuth = [
  ...authorityDepartment,
  ...authorityEmployee,
  ...authorityRole,
];

export const shipManagementAuth = [...badgeRead, ...textbookRead];
