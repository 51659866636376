import { auditionAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  afterClassStudyRead,
  auditionCourseRead,
  auditionLearningCoach,
  auditionRead,
  auditionVipPass,
  batchScheduledCoursesRead,
  courseFormalAuditionRead,
  courseReviewRead,
  courseTagRead,
  leaveRead,
  realtimeCourseRead,
} from 'routes/path';
import { TParameter } from 'services/RemoteConfigService/remoteConfigService.type';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateAuditionNav = (permissions: Auth[], parameter: TParameter) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(auditionAuth)) {
    return null;
  }

  return {
    title: RouteName.audition,
    path: path.AUDITION,
    icon: ICONS.calendar,
    children: (() => {
      const newNav = [];

      includeAuth(auditionLearningCoach) &&
        newNav.push({
          title: FirstRouteName.learningCoach,
          path: path.AUDITION_LEARNING_COACH,
        });

      includeAuth(auditionVipPass) &&
        newNav.push({
          title: FirstRouteName.vipPass,
          path: path.AUDITION_VIP_PASS,
        });

      includeAuth(auditionRead) &&
        newNav.push({
          title: FirstRouteName.reservation,
          path: path.AUDITION_RESERVATION,
        });

      includeAuth(auditionCourseRead) &&
        newNav.push({
          title: FirstRouteName.trialcourse,
          path: path.AUDITION_TRIAL_COURSE,
        });

      includeAuth(courseFormalAuditionRead) &&
        newNav.push({
          title: FirstRouteName.course,
          path: path.AUDITION_COURSE,
        });

      (parameter.COURSE_CLAIM || parameter.COURSE_CLAIM_AUDITION) &&
        includeAuth([
          Auth.COURSE_CLAIM_READ,
          Auth.COURSE_AUDITION_CLAIM_READ,
        ]) &&
        newNav.push({
          title: FirstRouteName.courseclaim,
          path: path.AUDITION_COURSE_CLAIM,
        });

      includeAuth(afterClassStudyRead) &&
        newNav.push({
          title: FirstRouteName.publicCourse,
          path: path.AUDITION_PUBLIC_COURSE,
        });

      includeAuth(batchScheduledCoursesRead) &&
        newNav.push({
          title: FirstRouteName.batchscheduledcourse,
          path: path.AUDITION_BATCH_SCHEDULED_COURSE,
        });

      includeAuth(realtimeCourseRead) &&
        newNav.push({
          title: FirstRouteName.realtimecourse,
          path: path.AUDITION_REALTIME_COURSE,
        });

      newNav.push({
        title: FirstRouteName.omscourse,
        path: path.AUDITION_OMS_COURSE,
      });

      includeAuth(courseTagRead) &&
        newNav.push({
          title: FirstRouteName.coursetag,
          path: path.AUDITION_COURSETAG,
        });

      parameter.ONECLASS_NOW &&
        newNav.push({
          title: FirstRouteName.nowcourse,
          path: path.AUDITION_NOW_COURSE,
        });

      includeAuth(courseReviewRead) &&
        newNav.push({
          title: FirstRouteName.coursereview,
          path: path.AUDITION_COURSE_REVIEW,
        });

      includeAuth(leaveRead) &&
        newNav.push({
          title: FirstRouteName.leave,
          path: path.AUDITION_LEAVE,
        });

      return newNav;
    })(),
  };
};

export { generateAuditionNav };
