import { NotificationTypes } from 'constants/notification.constants';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { Mui } from '@onedesign/ui';
import { Loader } from 'components/Loader';
import { Icon, SvgName } from 'components/Icon';
import { format } from 'date-fns';
import { alpha } from '@mui/material/styles';
import { Dialogs } from 'components/Dialogs';
import path from 'routes/path';
import { useMarkAllAsReadNotifications } from 'hooks/query/notificationCenter';
import { useSnackbar } from 'notistack';
import {
  LeaveOrderNotification,
  NormalNotification,
  TicketTransferNotification,
} from '../../TicketNotifyPopover';

const { Box, useTheme, Divider } = Mui;

type TicketNotificationItem = {
  id: string;
  type:
    | NotificationTypes.TICKETS_ASSIGN
    | NotificationTypes.TICKETS_NEW
    | NotificationTypes.TICKETS_TRANSFER_DEPARTMENT
    | NotificationTypes.TICKETS_TRANSFER_MYSELF;
  data: {
    departmentId: string;
    inquirer: string;
    ticketSn: number;
  };
  relatedObjectId: string;
  title: string;
  content: string;
  customerInfo: {
    name: string;
    oneClubId: string;
  };
  isRead: boolean;
  updatedAt: string;
};

type LeaveOrderNotificationItem = {
  id: string;
  type: NotificationTypes.LEAVE_ORDER_NEW;
  content: string;
  createdAt: string;
  customerInfo: {
    name: string;
    oneClubId: string;
  };
  isRead: boolean;
  relatedObjectId: string;
  title: string;
  updatedAt: string;
};

type TProps = {
  notificationList: (TicketNotificationItem | LeaveOrderNotificationItem)[];
  isLoading: boolean;
  handleOpenNotification: (
    data:
      | NormalNotification
      | TicketTransferNotification
      | LeaveOrderNotification,
  ) => void;
};

const TicketNotify = ({
  notificationList,
  isLoading,
  handleOpenNotification,
}: TProps) => {
  const theme = useTheme();
  const patchAllNotifications = useMarkAllAsReadNotifications();
  const { enqueueSnackbar } = useSnackbar();
  const [openMarkAllReadModal, setOpenMarkAllReadModal] = useState(false);

  const handleOpenNotificationCenter = () => {
    window.open(`${path.NOTIFICATION_CENTER}`, '_blank')?.focus();
  };

  const handleMarkAllAsRead = async () => {
    const { err } = await patchAllNotifications.mutateAsync();
    if (err !== 0) {
      enqueueSnackbar('改變全部已讀失敗');
    }
    setOpenMarkAllReadModal(false);
  };

  return (
    <>
      <Box
        sx={{
          width: '320px',
          height: '533px',
          color: theme.palette.grey[800],
          backgroundColor: 'white',
          boxShadow: `-20px 20px 40px -4px  ${alpha(
            theme.palette.grey[500],
            0.24,
          )}`,
          borderRadius: '12px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: '8px',
            px: '20px',
            lineHeight: '24px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            通知中心
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setOpenMarkAllReadModal(true)}
          >
            <Icon
              name={SvgName.DoneAll}
              sx={{
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 700,
                ml: '8px',
              }}
            >
              全部已讀
            </Typography>
          </Box>
        </Box>
        <Divider />
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader />
          </Box>
        )}
        {!isLoading && (
          <Box sx={{ height: '440px' }}>
            {notificationList.length > 0 &&
              notificationList.map(item => {
                return (
                  <>
                    <Box
                      key={item.id}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        py: '12px',
                        px: '16px',
                        height: '88px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleOpenNotification({
                          type: item.type,
                          departmentId:
                            item.type === NotificationTypes.LEAVE_ORDER_NEW
                              ? ''
                              : item.data.departmentId,
                          id: item.id,
                          relatedObjectId: item.relatedObjectId,
                          isRead: item.isRead,
                          oneClubId:
                            item.type === NotificationTypes.TICKETS_NEW
                              ? item.data.inquirer
                              : '',
                          ticketSn:
                            item.type === NotificationTypes.TICKETS_NEW
                              ? item.data.ticketSn
                              : 0,
                        })
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mb: '8px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: theme.palette.grey[800],
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '12px',
                            color: theme.palette.grey[500],
                          }}
                        >
                          {format(new Date(item.updatedAt), 'hh:mm a')}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '12px',
                          color: theme.palette.grey[500],
                          mb: '4px',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {item.content}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alighItems: 'center',
                        }}
                      >
                        {item.type !== NotificationTypes.TICKETS_ASSIGN && (
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '12px',
                              color: theme.palette.grey[500],
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '280px',
                            }}
                          >
                            {item.customerInfo.name && item.customerInfo.name} (
                            {item.customerInfo.oneClubId})
                          </Typography>
                        )}
                        {!item.isRead && (
                          <Box
                            sx={{
                              height: '12px',
                              width: '12px',
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                );
              })}
          </Box>
        )}
        {!isLoading && (
          <Box
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 700,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: '14px',
            }}
            onClick={handleOpenNotificationCenter}
          >
            查看全部
          </Box>
        )}
      </Box>
      <Dialogs
        open={openMarkAllReadModal}
        title="確定已讀所有通知？"
        close={{ text: 'x', action: () => setOpenMarkAllReadModal(false) }}
        confirm={{
          color: 'primary',
          text: '確定',
          action: () => handleMarkAllAsRead(),
        }}
        disConfirm={{
          color: 'inherit',
          text: '取消',
          action: () => setOpenMarkAllReadModal(false),
        }}
        width="480px"
      />
    </>
  );
};

export default TicketNotify;
