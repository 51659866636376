export enum ERemoteConfigParameter {
  MMS_TEACHER_SIGNING = 'MMS_TEACHER_SIGNING',
  ONECLUB_5214 = 'ONECLUB_5214',
  ONECLUB_5917 = 'ONECLUB_5917',
  ONECLUB_6237 = 'ONECLUB_6237',
  ONECLUB_6599 = 'ONECLUB_6599',
  ONECLUB_7167 = 'ONECLUB_7167',
  ONECLUB_7111 = 'ONECLUB_7111',
  ONE_ANSWER = 'ONE_ANSWER',
  NEW_BATCH_SCHEDULED_COURSE = 'NEW_BATCH_SCHEDULED_COURSE',
  ONECLASS_NOW = 'ONECLASS_NOW',
  NOW_ORDER = 'NOW_ORDER',
  ONECLUB_9182 = 'ONECLUB_9182',
  TEACHER_TAKE_LEAVE = 'TEACHER_TAKE_LEAVE',
  ONECLUB_10511 = 'ONECLUB_10511',
  MEMBER_UNBIND = 'MEMBER_UNBIND',
  COURSE_CLAIM = 'COURSE_CLAIM',
  LEARNING_PLAN_URL = 'LEARNING_PLAN_URL',
  CUSTOMER_CHANNEL_STATUS = 'CUSTOMER_CHANNEL_STATUS',
  COURSE_CLAIM_AUDITION = 'COURSE_CLAIM_AUDITION',
}
